export const sweetRow = [
  "Image",
  "Name",
  "Price",
  "Stock",
  "Offer Label",
  "Offer",
  "Offer Expiration",
  "Action"
];
const hindi = {
  gulabJamun: "गुलाब जामुन",
  jalebi: "जलेबी",
  kajukatli: "काजू कतली",
  laddoo: "लड्डू",
  rasgulla: "रसगुल्ला",
  chalerPayesh: "चालर पायेश",
  chachi: "चाची",
  kalakand: "कलाकंद",
  dudhPuli: "दूधपुली",
  rasmalai: "रसमलाई",
  basundi: "बसुंदी",
  peda: "पेड़ा",
  rabriFalooda: "राबड़ी फालूदा",
  malpua: "मालपुआ",
  barfi: "बर्फी",
  bundiyaPayesh: "बुंदिया पायेश",
  pistaKulfi: "पिस्ता कुल्फी",
  phirni: "फिरनी",
  bhapaSandesh: "भापा संदेश",
  milkcake: "दूध केक",
  besanBarfi: "बेसन की बर्फी",
  sujiHalwa: "सूजी हलवा",
  malaiChamCham: "मलाई छम छम"
};

const english = {
  gulabJamun: "Gulab Jamun",
  jalebi: "Jalebi",
  kajukatli: "Kaju Katli",
  laddoo: " Laddoo",
  rasgulla: "Rasgulla",
  chalerPayesh: "Chaler Payesh",
  chachi: "Chachi",
  kalakand: "Kalakand",
  dudhPuli: "Dudh Puli",
  rasmalai: "Rasmalai",
  basundi: "Basundi",
  peda: "Peda",
  rabriFalooda: "Rabri Falooda",
  malpua: "Malpua",
  barfi: "Barfi",
  bundiyaPayesh: "Bundiya Payesh",
  pistaKulfi: "Pista Kulfi",
  phirni: "Phirni",
  bhapaSandesh: "Bhapa Sandesh",
  milkcake: "Milkcake",
  besanBarfi: "besanBarfi",
  sujiHalwa: "Suji Halwa",
  malaiChamCham: "Malai Cham Cham"
};
